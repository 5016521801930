import { Box, Button, Divider, Grid, Modal } from "@mui/material";
import CustomTextInput from "../common/CustomTextInput/CustomTextInput";
import CustomSwitch from "../common/CustomSwitch/CustomSwitch";
import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { defaultCountry, defaultProjectValues, initialErrors, initialTouched, IProject, projectSchema } from "./schema";
import { addDoc, collection, doc, getFirestore, updateDoc } from "firebase/firestore";
import Dropdown from "../common/Dropdown/Dropdown";
import { GlobalContext } from "../App";
import SingleDatePicker from "../common/SingleDatePicker/SingleDatePicker";
import CancelIcon from "@mui/icons-material/Cancel";
import { teamOptions } from "../common/utils";
import { CountryComponent } from "./CountryComponent/CountryComponent";
import { dashboardClasses } from "./StyledDashboard";

// const StyledModalContent = styled(Box)(({ theme }) => ({
//     position: "absolute" as "absolute",
//     overflowY: "scroll",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 800,
//     height: 800,
//     backgroundColor: "white",
//     boxShadow: 24,
//     padding: 10,
//     // [theme.breakpoints.only("md")]: {
//     //     marginTop: "10%",
//     // },
// }));

const style = {
    position: "absolute" as "absolute",
    overflowY: "scroll",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 10,
};

const filterEmptyOrNA = (item: string) => {
    return item && item.toLowerCase() !== "n/a";
};

const getKeysValues = (values: IProject) => {
    return {
        countriesKeys: values.countryItems.map((countryItem) => countryItem.country),
        regionKeys: values.countryItems.map((countryItem) => countryItem.region),
        roFocalPointKeys: values.countryItems
            .map((countryItem) => countryItem.roFocalPoint?.lastName)
            .filter(filterEmptyOrNA),
        coFocalPointKeys: values.countryItems
            .map((countryItem) => countryItem.coFocalPoint?.lastName)
            .filter(filterEmptyOrNA),
        pricipalInvestigatorKeys: values.countryItems
            .map((countryItem) => countryItem.pricipalInvestigator?.lastName)
            .filter(filterEmptyOrNA),
        implementationPartnerKeys: values.countryItems
            .map((countryItem) => countryItem.implementationPartner)
            .filter(filterEmptyOrNA),
        fundingSourceKeys: values.countryItems.map((countryItem) => countryItem.fundingSource).filter(filterEmptyOrNA),
        agencyKeys: values.agencies.map((agency) => agency.agency).filter(filterEmptyOrNA),
        primaryCosponsorContactKeys: values.agencies
            .map((agency) => agency.primaryCosponsorContact?.lastName)
            .filter(filterEmptyOrNA),
    };
};

export const CreateProject = (props: { projectValues?: any; onClose: () => void }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { hrpPdrhDropdown, agenciesDropdown, scopes, statusesOfEngagement } = useContext(GlobalContext);

    const getInnerProjectValues = () => {
        return props.projectValues ? { ...props.projectValues } : { ...defaultProjectValues };
    };

    const form = useFormik<IProject>({
        initialValues: getInnerProjectValues() as IProject,
        validationSchema: projectSchema,
        initialErrors: { ...initialErrors },
        initialTouched: { ...initialTouched },
        onSubmit: async (values) => {
            // TODO: Move to service
            try {
                if (props.projectValues) {
                    const id = props.projectValues.id as string;
                    const docRef = doc(getFirestore(), "projects", id);
                    await updateDoc(docRef, {
                        ...values,
                        ...getKeysValues(values),
                    });
                } else {
                    await addDoc(collection(getFirestore(), "projects"), {
                        ...values,
                        ...getKeysValues(values),
                    });
                    form.resetForm();
                }
                handleClose();
                props.onClose();
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        },
    });

    const addCountry = () => {
        form.setFieldValue("countryItems", [...form.values.countryItems, { ...defaultCountry }]);
    };

    const addAgency = () => {
        form.setFieldValue("agencies", [
            ...form.values.agencies,
            {
                agency: "",
                primaryCosponsorContact: {
                    lastName: "",
                },
            },
        ]);
    };

    const removeAgency = (index: number) => {
        const newAgencies = [...form.values.agencies];
        newAgencies.splice(index, 1);
        form.setFieldValue("agencies", newAgencies);
    };

    const handleScopeChange = (innerName: string, value: any) => {
        if (form.values.scope !== value) {
            form.setFieldValue(innerName as any, value);

            if (value === "International") {
                form.setFieldValue("countryItems", [
                    {
                        ...defaultCountry,
                        country: "Global",
                        region: "Global",
                    },
                ]);
            } else if (value === "National") {
                addCountry();
            }
        }
    };

    const {
        code,
        title,
        description,
        team,
        endDate,
        hrpPdrh,
        shrFocalPoint,
        countryItems,
        hasCosponsorEngagement,
        agencies,
        statusOfEngagement,
        scope,
    } = form.values;

    return (
        <>
            <Button variant={"contained"} onClick={handleOpen}>
                {props.projectValues ? "Edit" : "Create new project"}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                sx={{ overflowY: "scroll" }}
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid container justifyContent={"space-between"} sx={{ marginTop: "10%" }}>
                        <Grid xs={2} item>
                            <CustomTextInput
                                name={"code"}
                                value={code}
                                onChange={form.handleChange}
                                label={"Project ID"}
                                className={""}
                                sx={{ width: "90%", margin: 1 }}
                                error={form.touched?.code && !!form.errors?.code}
                                helperText={form.errors?.code as any}
                            />
                        </Grid>
                        <Grid xs={10} item>
                            <CustomTextInput
                                name={"title"}
                                value={title}
                                onChange={form.handleChange}
                                label={"Project Title"}
                                className={""}
                                sx={{ width: "90%", margin: 1 }}
                                error={form.touched?.title && !!form.errors?.title}
                                helperText={form.errors?.title as any}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <CustomTextInput
                                name={"description"}
                                value={description}
                                onChange={form.handleChange}
                                label={"Description"}
                                className={""}
                                type={"textarea"}
                                rows={4}
                                sx={{ width: "90%", height: "100%", margin: 1 }}
                                error={(form.touched?.description as any) && !!form.errors?.description}
                                helperText={form.errors?.description as any}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={"space-between"}>
                        <Grid xs={4} item>
                            <Dropdown
                                name={`team`}
                                value={team}
                                onChange={(innerName, value) => {
                                    form.setFieldValue(innerName as any, value);
                                }}
                                label={"Team"}
                                className={""}
                                sx={{ width: "90%", margin: 1 }}
                                options={teamOptions}
                                error={form.touched?.team && !!form.errors?.team}
                                helperText={form.errors?.team as any}
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <SingleDatePicker
                                startDate={endDate}
                                sx={{ width: "90%", margin: 1 }}
                                error={form.touched?.endDate && !!form.errors?.endDate}
                                helperText={form.errors?.endDate as any}
                                dateInputClass={""}
                                placeholder={"End Date"}
                                label={"End Date"}
                                handleChange={(value) => {
                                    form.setFieldValue("endDate", value);
                                    form.setFieldValue("endDateYear", value.getFullYear());
                                }}
                                endAdornmentIcon={
                                    <CancelIcon
                                        className={dashboardClasses.cancelIcon}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            form.setFieldValue("endDate", null);
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <Dropdown
                                name={"hrpPdrh"}
                                value={hrpPdrh}
                                label={"HRP/PDRH"}
                                onChange={(_, value) => {
                                    form.setFieldValue("hrpPdrh", value);
                                }}
                                className={""}
                                sx={{ width: "90%", margin: 0 }}
                                options={hrpPdrhDropdown}
                                error={form.touched?.hrpPdrh && !!form.errors?.hrpPdrh}
                                helperText={form.errors?.hrpPdrh as any}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={"space-between"}>
                        <Grid container>
                            <Grid xs={4} item>
                                <CustomTextInput
                                    name={"shrFocalPoint.lastName"}
                                    value={shrFocalPoint.lastName}
                                    onChange={form.handleChange}
                                    label={"SRH Focal Point Last Name"}
                                    className={""}
                                    sx={{ width: "90%", margin: 1 }}
                                    error={
                                        form.touched?.shrFocalPoint?.lastName && !!form.errors?.shrFocalPoint?.lastName
                                    }
                                    helperText={form.errors?.shrFocalPoint?.lastName as any}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Divider sx={{ width: "100%" }} />
                            <CustomSwitch
                                title={"Cosponsor Engagement"}
                                label={"Cosponsor Engagement"}
                                className={""}
                                name={"hasCosponsorEngagement"}
                                value={hasCosponsorEngagement}
                                onChange={form.handleChange}
                                sx={{ height: "60px", margin: 1 }}
                            />
                            {hasCosponsorEngagement && (
                                <>
                                    <Grid container justifyContent={"space-between"}>
                                        <>
                                            {agencies.map((agency, index) => {
                                                return (
                                                    <Grid container key={index}>
                                                        <Grid item xs={4}>
                                                            <Grid container>
                                                                <Dropdown
                                                                    name={`agencies[${index}].agency`}
                                                                    value={agency.agency}
                                                                    onChange={(innerName, value) => {
                                                                        form.setFieldValue(innerName as any, value);
                                                                    }}
                                                                    label={"Agency Name"}
                                                                    className={""}
                                                                    sx={{ width: "90%", margin: 1 }}
                                                                    options={agenciesDropdown}
                                                                    error={
                                                                        form.errors?.agencies &&
                                                                        form.errors?.agencies[index] &&
                                                                        (form.touched?.agencies![index] as any)
                                                                            ?.agency &&
                                                                        !!(form.errors?.agencies![index] as any)?.agency
                                                                    }
                                                                    helperText={
                                                                        form.errors?.agencies &&
                                                                        form.errors?.agencies[index]
                                                                            ? (form.errors?.agencies![index] as any)
                                                                                  ?.agency
                                                                            : ""
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <CustomTextInput
                                                                    name={`agencies[${index}].primaryCosponsorContact.lastName`}
                                                                    value={agency.primaryCosponsorContact.lastName}
                                                                    onChange={form.handleChange}
                                                                    label={"Primary Cosponsor Contact Last Name"}
                                                                    className={""}
                                                                    sx={{ width: "90%", margin: 1 }}
                                                                    error={
                                                                        form.errors?.agencies &&
                                                                        form.errors?.agencies[index] &&
                                                                        (form.touched?.agencies![index] as any)
                                                                            ?.primaryCosponsorContact?.lastName &&
                                                                        !!(form.errors?.agencies![index] as any)
                                                                            ?.primaryCosponsorContact?.lastName
                                                                    }
                                                                    helperText={
                                                                        form.errors?.agencies &&
                                                                        form.errors?.agencies[index]
                                                                            ? ((form.errors?.agencies![index] as any)
                                                                                  ?.primaryCosponsorContact
                                                                                  ?.lastName as any)
                                                                            : ""
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        {agencies.length > 1 && (
                                                            <Grid item xs={4}>
                                                                <Button
                                                                    sx={{ width: "90%", margin: 1 }}
                                                                    onClick={() => removeAgency(index)}
                                                                    variant={"outlined"}
                                                                    color={"error"}>
                                                                    Remove Agency
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                        <Divider sx={{ width: "100%" }} />
                                                    </Grid>
                                                );
                                            })}
                                        </>
                                        <Button onClick={addAgency}>Add Agency</Button>
                                    </Grid>
                                    <hr />
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item xs={4}>
                                            <Grid container>
                                                <Dropdown
                                                    name={"statusOfEngagement"}
                                                    value={statusOfEngagement}
                                                    onChange={(innerName, value) => {
                                                        form.setFieldValue(innerName as any, value);
                                                    }}
                                                    label={"Status of Engagement"}
                                                    className={""}
                                                    sx={{ width: "90%", margin: 1 }}
                                                    options={statusesOfEngagement}
                                                    error={
                                                        form.touched?.statusOfEngagement &&
                                                        !!form.errors?.statusOfEngagement
                                                    }
                                                    helperText={form.errors?.statusOfEngagement as any}
                                                />
                                            </Grid>
                                            <Grid container>
                                                <Dropdown
                                                    name={"scope"}
                                                    value={scope}
                                                    onChange={(innerName, value) => handleScopeChange(innerName, value)}
                                                    label={"Scope"}
                                                    className={""}
                                                    sx={{ width: "90%", margin: 1 }}
                                                    options={scopes}
                                                    error={form.touched?.scope && !!form.errors?.scope}
                                                    helperText={form.errors?.scope as any}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        {scope !== "International" && (
                            <>
                                <Divider sx={{ width: "100%" }} />
                                <Grid container>
                                    {countryItems.map((countryItem, index) => {
                                        return (
                                            <CountryComponent
                                                key={index}
                                                form={form}
                                                index={index}
                                                countryItem={countryItem}
                                            />
                                        );
                                    })}
                                </Grid>
                                <Button sx={{ margin: 1 }} onClick={addCountry} variant={"outlined"}>
                                    Add Country
                                </Button>{" "}
                            </>
                        )}
                    </Grid>
                    <hr />

                    <Grid container>
                        <Button
                            onClick={() => {
                                form.submitForm();
                            }}>
                            {props.projectValues ? "Save" : "Create Project"}
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};
