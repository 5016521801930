import DateFnsUtils from "@date-io/date-fns";
// import firebase from "firebase/compat";
// import Timestamp = firebase.firestore.Timestamp;

export const dateFormat = "dd/MM/yyyy";
export const dateTimeFormat = "dd/MM/yyyy HH:mm";
export const dateFnUtils = new DateFnsUtils();

// TODO: Fix typing have a DTO and a model

export const timestampToDate = (timestamp: any): Date => {
    return new Date(timestamp.seconds * 1000);
};

export const formatFirebaseTimestamp = (timestamp: any) => {
    const date = timestampToDate(timestamp);
    if (!date) {
        return "";
    }
    return dateFnUtils.formatByString(date, dateFormat);
};

export const formatDate = (date: Date) => {
    return dateFnUtils.formatByString(date, dateFormat);
};

const teams = ["CFC", "MPH", "SHS", "PUA", "DIR", "SRT", "REL"];

export const teamOptions = teams.sort().map((team) => ({ id: team, name: team }));

export const cosponsorEngagementStatusOptions = [
    { id: true, name: "Yes" },
    { id: false, name: "No" },
];

export const fullNameOrEmpty = (nameObject: { lastName: string }) => {
    if (!nameObject) {
        return "N/A";
    }
    if (nameObject.lastName) {
        return `${nameObject.lastName}`;
    }
    return "N/A";
};
